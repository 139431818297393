// Blank L00K Template





// CSS
import '../styles/l00k__blank.scss';
import '../styles/l00k__luc.scss';
import '../styles/components/columns.scss';
import '../styles/components/headCompass.scss';

// SVG imports
import columnsSvg from 'bundle-text:../svg/columns.svg';
import headDailSvg from 'bundle-text:../svg/headDail.svg';
import fanSvg from 'bundle-text:../svg/fan3.svg';

// GSAP imports
import { gsap } from "gsap";
import { CustomEase } from "gsap/CustomEase";
// import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

// import * as Tone from 'tone';

// Register GSAP plugins
gsap.registerPlugin(CustomEase);

// JS


// Svg
// https://parceljs.org/languages/svg/#inlining-as-a-string
// import qrSvg from 'bundle-text:../svg/qr.svg';





// Thing



document.addEventListener('DOMContentLoaded', function () {
	// Initialize SVG elements
	initializeSVGs();
	
	// Initialize animations
	initializeColumnsAnimation();
	initializeHeadCompassAnimation();
	
	// Initialize navigation
	initializeNavigation();

	// Inject QR Svg
	// const qrSpans = document.querySelectorAll('.l00k__qrCode');
	// qrSpans.forEach(function (qrSpan) {
	// 	qrSpan.innerHTML = qrSvg;
	// });



    // L00K NAV
	var toggleLookNav = document.querySelectorAll('.toggle__l00k__nav');
	const mainLook = document.querySelectorAll('.l00k');

	toggleLookNav.forEach(function (toggleButton) {
		if (toggleButton instanceof HTMLButtonElement) {

			toggleButton.onclick = function (event) {

				mainLook.forEach(look => look.classList.toggle("w__l00k__nav"));

				if (toggleButton.getAttribute("aria-expanded") == "true") {
					toggleButton.setAttribute("aria-expanded", "false");
				} else {
					toggleButton.setAttribute("aria-expanded", "true");
				};

			};
		}
	});

	initializeFans();

}, false);

function initializeSVGs() {
	// Inject Columns SVG
	const columnsSpan = document.createElement('span');
	columnsSpan.innerHTML = columnsSvg;
	document.getElementById("l00k__cols")?.appendChild(columnsSpan);

	// Inject Head Compass SVG
	const headSpan = document.createElement('span');
	headSpan.innerHTML = headDailSvg;
	document.getElementById("l00k__head")?.appendChild(headSpan);

	// Inject Fan SVG
	const fanSpan = document.createElement('span');
	fanSpan.innerHTML = fanSvg;
	document.getElementById("l00k__fan")?.appendChild(fanSpan);
}

function initializeColumnsAnimation() {
	const columns = document.querySelector('#columns__svg');
	if (!columns) return;

	// Check for reduced motion preference
	const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

	if (prefersReducedMotion) {
		// Simple opacity changes only for reduced motion
		columns.addEventListener('mouseenter', () => {
			gsap.to(".leftCols__color", { duration: 0.3, opacity: 0.2 });
			gsap.to(".rightCols__color", { duration: 0.3, opacity: 0.3 });
		});

		columns.addEventListener('mouseleave', () => {
			gsap.to(".leftCols__color", { duration: 0.3, opacity: 1 });
			gsap.to(".rightCols__color", { duration: 0.3, opacity: 1 });
		});
	} else {
		// Original animation for users who don't prefer reduced motion
		// columns.addEventListener('mouseenter', () => {
		// 	const tl = gsap.timeline();
		// 	tl.add("tickerStart", "+=0.15")
		// 	  .to('.colTop__1', { 
		// 		  duration: 0.3, 
		// 		  y: -50,
		// 		  transformOrigin: "top center" 
		// 	  }, "tickerStart")
		// 	  .to('.colTop__2', { 
		// 		  duration: 0.15, 
		// 		  y: -44,
		// 		  transformOrigin: "top center" 
		// 	  }, "tickerStart");

		// 	gsap.to(".leftCols__color", { duration: 1.125, opacity: 0.2 });
		// 	gsap.to(".rightCols__color", { duration: 1.05, opacity: 0.3 });
		// });

		// columns.addEventListener('mouseleave', () => {
		// 	const tl = gsap.timeline();
		// 	tl.add("tickerStart", "+=0.15")
		// 	  .to('.colTop__1', { 
		// 		  duration: 0.15, 
		// 		  y: 0,
		// 		  transformOrigin: "top center" 
		// 	  }, "tickerStart")
		// 	  .to('.colTop__2', { 
		// 		  duration: 0.3, 
		// 		  y: 0,
		// 		  transformOrigin: "top center" 
		// 	  }, "tickerStart");

		// 	gsap.to(".leftCols__color", { duration: 1.125, opacity: 1 });
		// 	gsap.to(".rightCols__color", { duration: 1.05, opacity: 1 });
		// });
	}
}

function initializeHeadCompassAnimation() {
	const svg = document.querySelector(".dailSvg");
	if (!svg) return;

	// Check for reduced motion preference
	const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

	// Initial setup
	gsap.set(".northDail", { fill: 'white' });
	gsap.set(".dailGroup", { transformOrigin: "center" });

	if (prefersReducedMotion) {
		// No rotation animation for reduced motion
		return;
	}

	// Cursor following logic
	let requestId = null;
	const mouse = svg.createSVGPoint();

	function onFrame() {
		const point = mouse.matrixTransform(svg.getScreenCTM().inverse());
		rotateDail(point);
		requestId = null;
	}

	function onMouseMove(event) {
		mouse.x = event.clientX;
		mouse.y = event.clientY;
		
		if (!requestId) {
			requestId = requestAnimationFrame(onFrame);
		}
	}

	function rotateDail(point) {
		const element = document.querySelector(".dailGroup");
		const bbox = element.getBBox();
		const centerX = bbox.x + bbox.width / 2;
		const centerY = bbox.y + bbox.height / 2;
		
		const dx = point.x - centerX;
		const dy = point.y - centerY;
		const angle = Math.atan2(dy, dx);
		
		gsap.to(element, {
			duration: 2.5,
			rotation: angle + "_rad",
			ease: CustomEase.create("custom", "M0,0,C0.083,0.294,0.061,1.077,0.438,1.136,0.736,1.182,0.826,1,1,1")
		});
	}

	window.addEventListener("mousemove", onMouseMove);
}

function initializeNavigation() {
	const toggleLookNav = document.querySelectorAll('.toggle__l00k__nav');
	const mainLook = document.querySelectorAll('.l00k');

	toggleLookNav.forEach(function(toggleButton) {
		if (toggleButton instanceof HTMLButtonElement) {
			toggleButton.onclick = function() {
				mainLook.forEach(look => look.classList.toggle("w__l00k__nav"));
				toggleButton.setAttribute("aria-expanded", 
					toggleButton.getAttribute("aria-expanded") === "true" ? "false" : "true"
				);
			};
		}
	});
}

function initializeFans() {
	// Initialize fan states
	const fans = document.querySelectorAll(".fan-movement");
	gsap.set(fans, { transformOrigin: "center" });

	// Check for reduced motion preference
	const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;
	
	// Check if device is touch-enabled
	const isTouchDevice = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);

	if (prefersReducedMotion) {
		// Simple hover effect only for reduced motion
		fans.forEach(fan => {
			fan.addEventListener('mouseenter', () => {
				gsap.to(fan, { opacity: 0.8, duration: 0.3 });
			});
			
			fan.addEventListener('mouseleave', () => {
				gsap.to(fan, { opacity: 1, duration: 0.3 });
			});
		});
	} else if (isTouchDevice) {
		// Continuous spinning animation for mobile/touch devices
		fans.forEach(fan => {
			gsap.to(fan, {
				rotation: "-=360",
				duration: 2,
				ease: "none",
				repeat: -1,
				transformOrigin: "center"
			});

			// Optional: Pause/resume on tap
			fan.addEventListener('click', function() {
				const animation = gsap.getById(fan);
				if (animation.isActive()) {
					animation.pause();
				} else {
					animation.resume();
				}
			});
		});
	} else {
		// Mouse movement handler
		function handleMouseMove(event) {
			const xPos = (event.clientX / window.innerWidth) * 1.5;
			const yPos = (event.clientY / window.innerHeight) * 1.5;

			fans.forEach(fan => {
				gsap.to(fan, {
					duration: 1,
					rotation: xPos * 1720 + yPos * 1120,
					transformOrigin: "center",
					ease: "power2.out",
					onUpdate: function() {
						// Visual feedback during rotation
						const progress = this.progress();
						gsap.to(fan, {
							opacity: 0.9 + (progress * 0.3),
							duration: 0.1
						});
					}
				});
			});
		}

		// Event listeners
		window.addEventListener('mousemove', handleMouseMove);
		
		// Add hover effects
		fans.forEach(fan => {
			fan.addEventListener('mouseenter', () => {
				gsap.to(fan, {
					scale: 1.05,
					duration: 0.3,
					ease: "power2.out"
				});
			});
			
			fan.addEventListener('mouseleave', () => {
				gsap.to(fan, {
					scale: 1,
					duration: 0.3,
					ease: "power2.out"
				});
			});
		});

		// Cleanup function
		function cleanup() {
			window.removeEventListener('mousemove', handleMouseMove);
		}

		// Cleanup on page unload
		window.addEventListener('unload', cleanup);
	}
}